export default function previewImagesListener() {
  // 上傳官網圖片
  const content = document.getElementById("yield");
  const desktopImg = document.querySelector(".desktop-preview img");
  const desktop = document.getElementById("desktop");
  if (desktop) {
    document.getElementById("desktop").addEventListener("input", (ev) => {
      let file = ev.target.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let image_base64 = e.target.result;
        console.log(content.offsetWidth);
        desktopImg.setAttribute("style", `max-width:${content.offsetWidth - 40}px;object-fit: fill;`);
        desktopImg.setAttribute("src", image_base64);
      };
      reader.readAsDataURL(file);
    });
  }

  const mobileImg = document.querySelector(".mobile-preview img");
  const mobile = document.getElementById("mobile");
  if (mobile) {
    document.getElementById("mobile").addEventListener("input", (ev) => {
      let file = ev.target.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let image_base64 = e.target.result;
        mobileImg.setAttribute("style", `max-height:600px;object-fit:fill;`);
        mobileImg.setAttribute("src", image_base64);
      };
      reader.readAsDataURL(file);
    });
  }
}
