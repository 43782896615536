// 用來 開/關 單筆寄件時網頁上顯示的input欄位
// 單筆寄件畫面用來檢查輸入資料 店號 / 地址

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = [
    "select",
    "fee",
    "store",
    "storeInput",
    "storeInfo",
    "continue",
    "next",
  ];

  initialize() {
    this.feeTarget.required = false;
    this.feeTarget.setAttribute("disabled", "");
    this.selectTarget.value = sessionStorage.getItem("selectServiceType");
    this.toggleDisabled(sessionStorage.getItem("selectServiceType"));
    this.onInputStoreId();
  }

  // 輸入六碼店號查詢詳細門市資訊
  onInputStoreId() {
    var test = /^\d{6}$/.test(this.storeInputTarget.value);
    if (test) {
      this.queryStore(this.storeInputTarget.value);
    } else {
      this.continueTarget.disabled = true;
      this.nextTarget.disabled = true;
    }
  }

  queryStore(store) {
    var query = `store_id=${store}`;
    Rails.ajax({
      url: "/api/v2/store-info",
      type: "get",
      data: query,
      success: (data) => {
        this.storeInfoTarget.innerHTML = data.store_info;
        if (data.can_pick) {
          this.storeInfoTarget.classList.remove("text-red-500");
          this.continueTarget.disabled = false;
          this.nextTarget.disabled = false;
        } else {
          this.storeInfoTarget.classList.add("text-red-500");
          this.continueTarget.disabled = true;
          this.nextTarget.disabled = true;
        }
      },
      error: (data) => {
        console.error(data);
      },
    });
  }

  onChangeServiceType(ev) {
    // 用sessionStorage來存值，不然換地圖跳頁時會被清空
    sessionStorage.setItem("selectServiceType", ev.target.value);
    this.toggleDisabled(ev.target.value);
  }

  toggleDisabled(value) {
    // 代收or不代收
    if (value == "only_shipping") {
      this.feeTarget.required = false;
      this.feeTarget.disabled = true;
    } else if (value == "collecting") {
      this.feeTarget.required = true;
      this.feeTarget.disabled = false;
    }
  }
}
