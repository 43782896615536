import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["company", "store", "employee"];

  connect() {
    if (window.localStorage["company-id"]) {
      this.companyTarget.value = window.localStorage["company-id"];
    }
    if (window.localStorage["store-id"]) {
      this.storeTarget.value = window.localStorage["store-id"];
    }
    if (window.localStorage["employee-id"]) {
      this.employeeTarget.value = window.localStorage["employee-id"];
    }
  }
}
