import { Controller } from "@hotwired/stimulus";
// import $ from "jquery";

export default class extends Controller {
  connect() {
    document.getElementById("q").focus();
  }

  reset(ev) {
    ev.preventDefault();
    document.getElementById("q").readOnly = false;
    document.getElementById("barcode1").readOnly = false;
    document.getElementById("barcode2").readOnly = false;
    document.getElementById("q").value = null;
    document.getElementById("barcode1").value = null;
    document.getElementById("barcode2").value = null;
    document.getElementById("q").focus();
  }

  clear(ev) {
    ev.preventDefault();
    var list = document.getElementsByClassName("receivings");
    // .forEach((el) => el.remove());

    for (let el of list) {
      el.remove();
    }
    document.getElementById("counter").innerHTML = "0";
    document.getElementById("fee").innerHTML = "0";
  }

  // onEnter(ev) {
  //   if (ev.keyCode == 13) {
  //     ev.preventDefault();
  //     ev.target.nextElementSibling.focus();
  //   }
  // }

  // validateShipmentNumberAndNext(ev) {
  //   ev.preventDefault();

  //   if (ev.target.value.length == 16) {
  //     if (checkShipmentNumberValid(ev.target.value)) {
  //       // document.getElementById("barcode1").focus();
  //       document.getElementById("q").readOnly = true;
  //     } else {
  //       document.getElementById("q").value = null;
  //       document.getElementById("warning-message").innerHTML = "【提單條碼輸入檢查未通過】<p>請檢查是否打錯數字或條碼邊界污損不清而導致輸入失敗。</p>";
  //     }
  //   }
  // }

  // // validateBarcode1AndNext(ev) {
  // //   ev.preventDefault();

  // //   if (ev.target.value.length == 14) {
  // //     if (checkPickupBarcode1Valid(ev.target.value)) {
  // //       // document.getElementById("barcode2").focus();
  // //       document.getElementById("barcode1").readOnly = true;
  // //     } else {
  // //       document.getElementById("barcode1").value = null;
  // //       document.getElementById("warning-message").innerHTML = "【第一段領貨條碼輸入檢查未通過】<p>請檢查是否打錯數字或條碼邊界污損不清而導致輸入失敗。</p>";
  // //     }
  // //   }
  // // }

  // validateBarcode2AndNext(ev) {
  //   if (ev.target.value.length == 22) {
  //     if (checkPickupBarcode2Valid(ev.target.value)) {
  //       document.getElementById("barcode2").readOnly = true;
  //     } else {
  //       document.getElementById("barcode2").value = null;
  //       document.getElementById("warning-message").innerHTML = "【第二段領貨條碼輸入檢查未通過】<p>請檢查是否打錯數字或條碼邊界污損不清而導致輸入失敗。</p>";
  //     }
  //   }
  // }
}

// 以下local檢查用
function shiftCharCode(Δ) {
  return function (c) {
    return String.fromCharCode(c.charCodeAt(0) + Δ);
  };
}

function toFullWidth(str) {
  return str.replace(/[!-~]/g, shiftCharCode(0xfee0));
}

function toHalfWidth(str) {
  return str.replace(/[！-～]/g, shiftCharCode(-0xfee0));
}

function checkMobileLast3Valid(value) {
  value = toHalfWidth(value);
  if (RegExp(/^[0-9]{3}$/).test(value)) {
    return true;
  } else {
    return false;
  }
}

function checkShipmentNumberValid(value) {
  value = toHalfWidth(value);
  if (!RegExp(/^[0-9]{16}$/).test(value)) {
    return false;
  } else {
    let digits = value.split("");
    let sum = 0;
    sum += (parseInt(digits[4]) * 7) % 10;
    sum += (parseInt(digits[5]) * 9) % 10;
    sum += (parseInt(digits[6]) * 1) % 10;
    sum += (parseInt(digits[7]) * 2) % 10;
    sum += (parseInt(digits[8]) * 3) % 10;
    sum += (parseInt(digits[9]) * 4) % 10;
    sum += (parseInt(digits[10]) * 5) % 10;
    sum += (parseInt(digits[11]) * 6) % 10;
    sum += (parseInt(digits[12]) * 7) % 10;
    sum += (parseInt(digits[13]) * 8) % 10;
    sum += (parseInt(digits[14]) * 9) % 10;

    if (sum % 10 == parseInt(digits[15])) {
      return true;
    } else {
      return false;
    }
  }
}

function checkPickupBarcode1Valid(value) {
  value = toHalfWidth(value);
  if (!RegExp(/^[0-9]{14}$/).test(value)) {
    return false;
  } else {
    return true;
  }
}

function checkPickupBarcode2Valid(value) {
  value = toHalfWidth(value);
  if (!RegExp(/^[0-9]{22}$/).test(value)) {
    return false;
  } else {
    let digits = value.split("");
    let sum = 0;
    sum += (parseInt(digits[0]) * 2) % 10;
    sum += (parseInt(digits[1]) * 7) % 10;
    sum += (parseInt(digits[2]) * 7) % 10;
    sum += (parseInt(digits[3]) * 5) % 10;
    sum += (parseInt(digits[4]) * 2) % 10;
    sum += (parseInt(digits[5]) * 9) % 10;
    sum += (parseInt(digits[6]) * 0) % 10;
    sum += (parseInt(digits[7]) * 4) % 10;
    sum += (parseInt(digits[8]) * 2) % 10;
    sum += (parseInt(digits[9]) * 7) % 10;
    sum += (parseInt(digits[10]) * 7) % 10;
    sum += (parseInt(digits[11]) * 5) % 10;
    sum += (parseInt(digits[12]) * 2) % 10;
    sum += (parseInt(digits[13]) * 9) % 10;
    sum += (parseInt(digits[14]) * 0) % 10;
    sum += (parseInt(digits[15]) * 4) % 10;
    sum += (parseInt(digits[16]) * 2) % 10;
    sum += (parseInt(digits[17]) * 7) % 10;
    sum += (parseInt(digits[18]) * 7) % 10;
    sum += (parseInt(digits[19]) * 5) % 10;
    sum += (parseInt(digits[20]) * 2) % 10;

    if (sum % 10 == parseInt(digits[21])) {
      return true;
    } else {
      return false;
    }
  }
}

function decipherPaymentAmount(value) {
  value = toHalfWidth(value);
  return value["15"] == "1" ? parseInt(value.slice(16, 21), 10) : 0;
}
