// import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
// import * as ActiveStorage from "@rails/activestorage";
// import "channels";
// import "stylesheets/application.scss";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
// require("channels");
require("jquery-ui");

// actiontext
require("trix");
require("@rails/actiontext");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

// stimulus
import "controllers";
// stylesheets
import "stylesheets/application";
import "stylesheets/actiontext";

// TODO: 以下這裏我要改掉, 但先整合統一完了再說
async function removeReadOnly(ev) {
  ev.target.removeAttribute("readonly");
}

import previewImagesListener from "../application/previewImages";
import fetchStoreListener from "../application/fetchStore";

jQuery(function () {
  if (
    document.querySelector('meta[name="company-id"]') &&
    document.querySelector('meta[name="company-id"]').content != ""
  ) {
    localStorage.setItem(
      "company-id",
      document.querySelector('meta[name="company-id"]').content
    );
  }

  if (
    document.querySelector('meta[name="current-store-id"]') &&
    document.querySelector('meta[name="current-store-id"]').content != ""
  ) {
    localStorage.setItem(
      "store-id",
      document.querySelector('meta[name="current-store-id"]').content
    );
  }

  if (
    document.querySelector('meta[name="employee-id"]') &&
    document.querySelector('meta[name="employee-id"]').content != ""
  ) {
    localStorage.setItem(
      "employee-id",
      document.querySelector('meta[name="employee-id"]').content
    );
  }

  if (document.getElementById("resolution")) {
    var intFrameWidth = window.innerWidth;
    var intFrameHeight = window.innerHeight;
    document.getElementById(
      "resolution"
    ).innerHTML = `${intFrameWidth} x ${intFrameHeight}`;
  }

  // 背景獲取門市資訊
  fetchStoreListener();
  // 預覽上傳官網圖片
  previewImagesListener();

  // close alert automatically
  if (document.querySelector(".site-alert")) {
    setTimeout(function () {
      $(".site-alert").alert("close");
    }, 3000);
  }
});
