import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  static values = {
    marketId: String,
    mercId: String,
  };

  connect() {
    // fetch(this.marketIdValue);
  }

  onClick() {
    if (this.checkboxTarget.checked) {
      this.removeMerc();
    } else {
      this.appendMerc();
    }
  }

  onChangeCart() {
    if (location.pathname == "/cart") {
      this.updateCart();
    }
  }

  appendMerc() {
    let payload = new URLSearchParams();
    payload.append(`merc_id`, this.mercIdValue);

    setTimeout(() => {
      Rails.ajax({
        url: `/marketplace/${this.marketIdValue}/append_merc`,
        type: "post",
        data: payload,
        success: (data) => {
          console.log(data);
          // window.location.reload();
        },
        error: (data) => {
          console.error(data);
        },
      });
    }, 100);
  }

  removeMerc() {
    let payload = new URLSearchParams();
    payload.append(`merc_id`, this.mercIdValue);

    setTimeout(() => {
      Rails.ajax({
        url: `/marketplace/${this.marketIdValue}/remove_merc`,
        type: "post",
        data: payload,
        success: (data) => {
          console.log(data);
          // window.location.reload();
        },
        error: (data) => {
          console.error(data);
        },
      });
    }, 100);
  }
}
