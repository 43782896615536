import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bankCode", "bankName", "branchCode", "branchName"];

  get bankCode() {
    return this.targets.find("bankCode").value;
  }

  get branchCode() {
    return this.targets.find("branchCode").value;
  }

  onChangeBank() {
    // this.setBankNameByCode();
    this.getBankBranchesByCode();
  }

  // setBankNameByCode() {
  //   this.targets.find("bankName").value = this.targets.find("bankCode").text;
  // }

  getBankBranchesByCode() {
    const bankCode = this.bankCode;

    Rails.ajax({
      type: "GET",
      url: "/profile/bank-branches",
      data: "q=" + bankCode,
      // success: (data) => {
      //   // message();
      //   console.log(data);
      // },
    });
  }

  // setSelected() {
  //   document.querySelector(`option[value='']`);
  // }

  // setBranchNameByCode() {
  //   let branchLast4 = this.remit_bank_branch_code.substring(3);
  //   let branch = this.bankbranches.filter((branch) => branch.branch_code == branchLast4);
  //   this.remit_bank_branch = branch[0].branch_name;
  // }
}
