// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";
import { toggle } from "el-transition";

let mobileMenuOpen = false;

export default class extends Controller {
  static targets = [
    "toggleable",
    "mobileMenu",
    "mobileButtonMenu",
    "mobileButtonClose",
  ];
  toggle() {
    toggle(this.toggleableTarget);
  }

  toggleMobile() {
    toggle(this.mobileMenuTarget);
    mobileMenuOpen = !mobileMenuOpen;
    console.log(mobileMenuOpen);

    if (mobileMenuOpen) {
      this.mobileButtonMenuTarget.classList.add("hidden");
      this.mobileButtonMenuTarget.classList.remove("block");
      this.mobileButtonCloseTarget.classList.add("block");
      this.mobileButtonCloseTarget.classList.remove("hidden");
    } else {
      this.mobileButtonMenuTarget.classList.add("block");
      this.mobileButtonMenuTarget.classList.remove("hidden");
      this.mobileButtonCloseTarget.classList.add("hidden");
      this.mobileButtonCloseTarget.classList.remove("block");
    }
  }
}
