import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

let banks,
  branches = "";

let branchChanged = false;

export default class extends Controller {
  static targets = ["bank", "branch", "area", "multi"];

  connect() {
    banks = $("#user_remit_bank_code").html();
    branches = $("#user_remit_bank_branch_code").html();
    // $("#user_remit_bank_code").empty();
    // $("#user_remit_bank_code").html("<option selected disabled>選擇我的出生城市</option>");
    // $("#user_remit_bank_branch_code").empty();
    // $("#user_remit_bank_branch_code").html("<option selected disabled>選擇我的出生地區</option>");

    this.onChangeBank();
  }

  onChangeBank() {
    var bank =
      this.targets.find("bank").options[this.targets.find("bank").selectedIndex]
        .text;
    var escaped_bank = bank.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1");
    var options = $(branches).filter(`optgroup[label=${escaped_bank}]`).html();

    if (options) {
      $("#user_remit_bank_branch_code").html(
        "<option selected disabled>選擇" + bank + "匯款分行</option>" + options
      );
    } else {
      // $("#user_remit_bank_branch_code").empty();
      $("#user_remit_bank_branch_code").html(
        "<option selected disabled>選擇" + bank + "匯款分行</option>"
      );
    }

    // if (branchChanged) {
    //   // $("#user_remit_bank_branch_code").empty();
    //   $("#user_remit_bank_branch_code").html("<option selected disabled>選擇" + bank + "匯款分行</option>");
    //   branchChanged = false;
    // }
  }

  onChangeBranch() {
    // branchChanged = true;
    // // console.log(this.targets.find("branch").value);
    // var branch = this.targets.find("branch").options[this.targets.find("branch").selectedIndex].text;
    // var escaped_branch = branch.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1");
    // var options = $(branches).filter(`optgroup[label=${escaped_branch}]`).html();
    // // console.log(options);
    // if (options) {
    //   $("#user_remit_bank_branch_code").html("<option selected disabled>選擇匯款銀行</option>" + options);
    // } else {
    //   // $("#user_remit_bank_branch_code").empty();
    //   $("#user_remit_bank_branch_code").html("<option selected disabled>選擇匯款分行</option>");
    // }
  }
}
