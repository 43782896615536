import { Controller } from "@hotwired/stimulus";
// import { enter, leave } from "el-transition";

export default class extends Controller {
  // static targets = ["notification"];
  // initialize() {
  //   enter(this.notificationTarget);
  //   console.log("init");
  // }
  // close() {
  //   leave(this.notificationTarget);
  //   console.log("closed");
  // }
}
