// 用來 開/關 單筆寄件時網頁上顯示的input欄位
// 單筆寄件畫面用來檢查輸入資料 店號 / 地址

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

let cities,
  areas = "";

export default class extends Controller {
  static targets = [
    "select",
    "town",
    "collecting",
    "freight",
    "freightInfo",
    "freight",
    "zip",
    "address",
    "addrInput",
    "addrInfo",
    "roadno",
    "continue",
    "next",
    "city",
    "area",
  ];

  initialize() {
    cities = this.cityTarget.innerHTML;
    areas = this.areaTarget.innerHTML;

    this.freightTarget.required = false;
    this.freightTarget.setAttribute("disabled", "");
    this.selectTarget.value = sessionStorage.getItem("selectServiceType");
    this.toggleDisabled(sessionStorage.getItem("selectServiceType"));

    this.roadnoTarget.innerHTML = "";

    if (this.targets.find("city").selectedIndex > 0) {
      this.onChangeCity();
    }
  }

  onChangeCity() {
    // ensure user select city first
    this.areaTarget.disabled = false;
    var city =
      this.targets.find("city").options[this.targets.find("city").selectedIndex]
        .text;

    var options = $(areas).filter(`optgroup[label=${city}]`).html();

    if (options === undefined) {
      $("#area").html("<option selected disabled>請先選擇縣市</option>");
    } else if (options.trim()) {
      $("#area").html(
        "<option selected disabled>" + city + "服務區域</option>" + options
      );
    } else {
      $("#area").html(
        "<option selected disabled>" + city + "目前未服務</option>"
      );
    }
  }

  onChangeArea(ev) {
    // new
    var url =
      this.selectTarget.value == "collecting"
        ? "/api/v2/to_house_collecting"
        : "/api/v2/to_house";
    Rails.ajax({
      url: url,
      type: "get",
      data: new URLSearchParams({ id: this.areaTarget.value }).toString(),
      success: (data) => {
        this.freightInfoTarget.innerHTML = `${data.freight}元`;
      },
      error: (data) => {
        console.error(data);
      },
    });
  }

  onChangeServiceType(ev) {
    // 用sessionStorage來存值，不然換地圖跳頁時會被清空
    sessionStorage.setItem("selectServiceType", ev.target.value);
    this.toggleDisabled(ev.target.value);
  }

  toggleDisabled(value) {
    // 代收or不代收
    if (value == "only_shipping") {
      this.freightTarget.required = false;
      this.freightTarget.disabled = true;
    } else if (value == "collecting") {
      this.freightTarget.required = true;
      this.freightTarget.disabled = false;
    }
  }
}
