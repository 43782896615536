import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editable"];

  doubleClick(event) {
    event.preventDefault();

    let editor = document.createElement("input");
    editor.value = event.target.innerText;

    // console.log(event.target.classList);

    // let style = window.getComputedStyle(event.target);
    // editor.style.cssText = style.cssText;

    editor.classList.add(...event.target.classList);

    editor.addEventListener(
      "keypress",
      function (event) {
        console.log(event);
        var key = event.which || event.keyCode;
        // 13 is enter // 27 is escape
        if (key == 13 || key == 27) {
          console.log(key);
          this.editableTarget.innerText = editor.value;
          this.editableTarget.classList.remove("hidden");
          editor.remove();
        }
      }.bind(this)
    );

    let selection = window.getSelection();
    editor.setSelectionRange(selection.anchorOffset, selection.anchorOffset);
    editor.focus();

    this.editableTarget.insertAdjacentElement("afterend", editor);
    this.editableTarget.classList.add("hidden");
  }
}
