// document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
//   anchor.addEventListener("click", function (e) {
//     e.preventDefault();

//     document.querySelector(this.getAttribute("href")).scrollIntoView({
//       behavior: "smooth",
//     });
//   });
// });

// 用來 預估賣場商品運費

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["anchor"];

  initialize() {}

  onClick(ev) {
    ev.preventDefault();
    document
      .querySelector(this.anchorTarget.getAttribute("href"))
      .scrollIntoView({
        behavior: "smooth",
      });
  }
}
