// 用來 預估賣場商品運費

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["town", "feeInfo"];

  initialize() {}

  onSelectCityTown(ev) {
    var str = ev.target.options[ev.target.selectedIndex].text;
    var arr = str.split(" ");
    var query = `zip=${arr[0]}&city=${arr[1]}&town=${arr[2]}&collecting=collecting`;

    Rails.ajax({
      url: "/api/v2/address_to_freight",
      type: "get",
      data: query,
      success: (data) => {
        this.feeInfoTarget.innerHTML = `$${data.fee}元`;
      },
      error: (data) => {
        console.error(data);
      },
    });
  }
}
