import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reason", "destination"];

  connect() {
    this.onChangeReason();
  }

  onChangeReason() {
    var reason =
      this.targets.find("reason").options[
        this.targets.find("reason").selectedIndex
      ].value;

    console.log(reason);
    if (reason == "14147") {
      this.destinationTarget.required = true;
    } else {
      this.destinationTarget.required = false;
    }
  }
}
