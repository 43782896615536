import { Controller } from "@hotwired/stimulus";
import { enter, leave, toggle } from "el-transition";
import $ from "jquery";
// use jquery for its .filter make life easier

let cities,
  areas = "";

let currentMethod = 0;
let dropdownOpen = false;

export default class extends Controller {
  static targets = [
    "method",
    "deliveryMethod",
    "freightToStore",
    "freightToHouse",
    "cityBlock",
    "areaBlock",
    "city",
    "area",
    "cellphone",
    "phone",
    "freight",
    "subtotal",
    "grandtotal",
    "quantity",
    "plus",
    "minus",
    "dropdown",
    "storeInput",
    "storeInfo",
    "submit",
  ];

  connect() {
    cities = this.cityTarget.innerHTML;
    areas = this.areaTarget.innerHTML;

    // 0 = include_blank
    if (this.targets.find("deliveryMethod").selectedIndex > 0) {
      this.onChangeDeliveryMethod();
    }

    if (this.targets.find("city").selectedIndex > 0) {
      this.onChangeCity();
    }
    if (this.targets.find("area").selectedIndex > 0) {
      this.onChangeArea();
    }
  }

  onChangeDeliveryMethod() {
    currentMethod = this.targets.find("deliveryMethod").selectedIndex;

    var method =
      this.targets.find("deliveryMethod").options[currentMethod].value;

    if (method == "to_store") {
      leave(this.freightToHouseTarget);
      enter(this.freightToStoreTarget);
      leave(this.phoneTarget);
      enter(this.cellphoneTarget);
      // store relative
      document.getElementById("order_receiver_store").required = true;
      // house relative
      document.getElementById("order_city").required = false;
      document.getElementById("order_area").required = false;
      document.getElementById("order_receiver_address").required = false;

      // 送上server的時候順便會在session裡面寫運費
      // Api::V2::FreightController
      Rails.ajax({
        url: "/api/v2/market_to_store",
        type: "get",
        success: (data) => {
          this.freightTarget.innerHTML = data.freight;
          this.grandtotalTarget.innerHTML =
            parseInt(data.freight, 10) +
            parseInt(this.subtotalTarget.innerHTML, 10);
          this.freightTarget.classList.remove("text-gray-300");
          this.freightTarget.classList.add("text-gray-700");
          this.grandtotalTarget.classList.remove("text-gray-300");
          this.grandtotalTarget.classList.add("text-gray-700");
        },
        error: (data) => {
          console.error(data);
        },
      });
    } else if (method == "to_house") {
      leave(this.freightToStoreTarget);
      enter(this.freightToHouseTarget);
      leave(this.cellphoneTarget);
      enter(this.phoneTarget);
      // store relative
      document.getElementById("order_receiver_store").required = false;
      // house relative
      document.getElementById("order_city").required = true;
      document.getElementById("order_area").required = true;
      document.getElementById("order_area").disabled = false;
      document.getElementById("order_receiver_address").required = true;

      this.submitTarget.disabled = false;

      this.grandtotalTarget.innerHTML = this.subtotalTarget.innerHTML;
      this.freightTarget.innerHTML = "0";
      this.freightTarget.classList.add("text-gray-300");
      this.freightTarget.classList.remove("text-gray-700");
      this.grandtotalTarget.classList.add("text-gray-300");
      this.grandtotalTarget.classList.remove("text-gray-700");
      this.onChangeCity;
    } else {
      leave(this.freightToStoreTarget);
      leave(this.freightToHouseTarget);
      leave(this.cellphoneTarget);
      leave(this.phoneTarget);
      this.grandtotalTarget.innerHTML = this.subtotalTarget.innerHTML;
      this.freightTarget.innerHTML = "0";
      this.freightTarget.classList.add("text-gray-300");
      this.freightTarget.classList.remove("text-gray-700");
      this.grandtotalTarget.classList.add("text-gray-300");
      this.grandtotalTarget.classList.remove("text-gray-700");
    }
  }

  onChangeCity() {
    // ensure user select city first
    this.areaTarget.disabled = false;
    var city =
      this.targets.find("city").options[this.targets.find("city").selectedIndex]
        .text;

    var options = $(areas).filter(`optgroup[label=${city}]`).html();

    if (options === undefined) {
      $("#order_area").html("<option selected disabled>請先選擇縣市</option>");
    } else if (options.trim()) {
      $("#order_area").html("<option selected disabled></option>" + options);
    } else {
      $("#order_area").html("<option selected disabled></option>");
    }
  }

  onChangeArea() {
    // 送上server的時候順便會在session裡面寫運費
    // Api::V2::FreightController
    Rails.ajax({
      url: "/api/v2/market_to_house",
      type: "get",
      data: new URLSearchParams({
        id: this.areaTarget.value,
        city: this.cityTarget.value,
      }).toString(),
      success: (data) => {
        this.freightTarget.innerHTML = data.freight;
        this.grandtotalTarget.innerHTML =
          parseInt(data.freight, 10) +
          parseInt(this.subtotalTarget.innerHTML, 10);
        this.freightTarget.classList.remove("text-gray-300");
        this.freightTarget.classList.add("text-gray-700");
        this.grandtotalTarget.classList.remove("text-gray-300");
        this.grandtotalTarget.classList.add("text-gray-700");
      },
      error: (data) => {
        console.error(data);
      },
    });
  }

  // 輸入六碼店號查詢詳細門市資訊
  onInputStoreId() {
    var test = /^\d{6}$/.test(this.storeInputTarget.value);
    if (test) {
      this.queryStore(this.storeInputTarget.value);
    } else {
      this.continueTarget.disabled = true;
      this.nextTarget.disabled = true;
    }
  }

  queryStore(store) {
    var query = `store_id=${store}`;
    Rails.ajax({
      url: "/api/v2/store-info",
      type: "get",
      data: query,
      success: (data) => {
        this.storeInfoTarget.innerHTML = data.store_info;
        if (data.can_pick) {
          this.storeInfoTarget.classList.remove("text-red-500");
          this.submitTarget.disabled = false;
        } else {
          this.storeInfoTarget.classList.add("text-red-500");
          this.submitTarget.disabled = true;
        }
      },
      error: (data) => {
        console.error(data);
      },
    });
  }

  toggleDropdown() {
    toggle(this.dropdownTarget);
    dropdownOpen = !dropdownOpen;
  }
}
