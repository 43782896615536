import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  clickAndCopy(e) {
    e.preventDefault();
    e.target.select();
    document.execCommand("copy");
  }

  clickSpanAndCopy(e) {
    var textArea = document.createElement("textarea");
    textArea.value = e.target.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  }
}
