import { Controller } from "@hotwired/stimulus";

import * as basicLightbox from "basiclightbox";

export default class extends Controller {
  connect() {}

  onClick(ev) {
    basicLightbox
      .create(`<img width="1336" height="704" src="${ev.params.url}">`)
      .show();
  }
}
