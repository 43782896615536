import Clipboard from "stimulus-clipboard";

export default class extends Clipboard {
  connect() {
    super.connect();
  }

  copyInner(event) {
    event.preventDefault();

    var textArea = document.createElement("textarea");
    textArea.value = this.sourceTarget.innerText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    this.copied();
  }
}
