import { Controller } from "@hotwired/stimulus";
import html2canvas from "html2canvas";
import moment from "moment-timezone";

export default class extends Controller {
  static targets = ["button", "timestamp", "receipt"];

  print() {
    this.timestampTarget.innerHTML = moment
      .tz(new Date(), "Asia/Taipei")
      .format()
      .replace(/T/, " ")
      .replace(/\+.*$/, "");
    this.receiptTarget.classList.remove("hidden");

    html2canvas(this.receiptTarget, {
      logging: true,
      width: this.receiptTarget.offsetWidth,
      height: this.receiptTarget.offsetHeight,
      scrollY: -window.scrollY,
    }).then(async (canvas) => {
      let srcBase64 = await canvas.toDataURL("image/png");

      if (process.env.NODE_ENV == "development") {
        console.log(srcBase64.split(",")[1]);
        this.receiptTarget.classList.add("hidden");
        this.buttonTarget.disabled = true;
      } else {
        let done = await Android.printReceipt(srcBase64.split(",")[1]);
        this.receiptTarget.classList.add("hidden");
        this.buttonTarget.disabled = true;
        return done;
      }
    });
  }
}
