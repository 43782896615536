import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// const token = document.getElementsByName("csrf-token")[0].content;

function serializeList(container) {
  var str = "";
  var n = 0;
  var els = container.find("div.sortable-item");

  for (var i = 0; i < els.length; ++i) {
    var el = els[i];
    var p = el.id.lastIndexOf("_");
    if (p != -1) {
      if (str != "") str = str + "&";
      str = str + el.id.substring(0, p) + "[]=" + el.id.substring(p + 1);
      ++n;
    }
  }
  return str;
}

export default class extends Controller {
  static targets = ["storeId", "storeInfo"];

  initialize() {
    this.onInput();
  }

  addMerchandiseInfo() {
    this.infos.push({
      size: "",
      color: "",
      stock: "",
      price: "",
    });
  }
}
