import axios from "axios";

export default function fetchStoreListener() {
  const input = document.getElementById("q");
  if (input) {
    input.addEventListener("input", fetchStore);
  }
}

async function fetchStore(e) {
  if (e.target.value.length == 6) {
    let resp = await axios.get("/api/v2/store-info", {
      params: {
        store_id: e.target.value,
      },
    });

    document.getElementById(
      "info"
    ).innerHTML = `${resp.data.company_name}${resp.data.store_name}${resp.data.store_address}`;
  }
}
