import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

let banks,
  branches = "";

export default class extends Controller {
  static targets = [
    "banks",
    "branch",
    "area",
    "multi",
    "invoiceType",
    "invoiceTitle",
    "vatNumber",
  ];

  static values = {
    action: String,
  };

  connect() {
    if (this.actionValue == "edit") {
      banks = this.banksTarget;
      branches = $("#member_remit_bank_branch_code").html();
      this.onChangeBank();
    }
  }

  onChangeInvoiceType() {
    var invoice =
      this.targets.find("invoiceType").options[
        this.targets.find("invoiceType").selectedIndex
      ].value;
    if (invoice == "business") {
      this.invoiceTitleTarget.required = true;
      this.vatNumberTarget.required = true;
    } else {
      this.invoiceTitleTarget.required = false;
      this.vatNumberTarget.required = false;
    }
  }

  onChangeBank() {
    var bank = this.banksTarget.options[this.banksTarget.selectedIndex].text;

    if (bank.length > 0) {
      this.branchTarget.required = true;
      var escaped_bank = bank
        .replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1")
        .replace(/(\d+)/g, "");

      var options = $(branches)
        .filter(`optgroup[label=${escaped_bank}]`)
        .html();

      if (options) {
        $("#member_remit_bank_branch_code").html(
          "<option selected disabled></option>" + options
        );
      } else {
        $("#member_remit_bank_branch_code").html(
          "<option selected disabled></option>"
        );
      }
    } else {
      this.branchTarget.required = false;
    }
  }
}
