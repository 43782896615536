// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";
import { toggle, enter, leave } from "el-transition";

let mobileMenuOpen = false;
let serviceFlyoutOpen = false;
let profileFlyoutOpen = false;
let cartFlyoutOpen = false;
let shipmentDropdownOpen = false;

export default class extends Controller {
  static targets = [
    "toggleable",
    "mobileMenu",
    "profileFlyout",
    "cartFlyout",
    "shipmentDropdown",
    "affiliates",
    "actions",
    "tracking",
    "search",
    "market",
    "faq",
  ];

  connect() {
    document.addEventListener("keydown", this.closeHandler.bind(this));
    this.setActive();
  }

  disconnect() {
    document.removeEventListener("keydown", this.closeHandler.bind(this));
  }

  setActive() {
    const path = window.location.pathname;
    switch (true) {
      case /^\/affiliates/.test(path):
        this.affiliatesTarget.classList.add(
          "border-indigo-600",
          "text-indigo-600"
        );
        break;

      case /^\/shipping/.test(path):
        this.actionsTarget.classList.add(
          "border-indigo-600",
          "text-indigo-600"
        );
        break;

      case /^\/tracking/.test(path):
        this.trackingTarget.classList.add(
          "border-indigo-600",
          "text-indigo-600"
        );
        break;

      case /^\/search-by/.test(path):
        this.searchTarget.classList.add("border-indigo-600", "text-indigo-600");
        break;

      case /^\/market/.test(path):
        this.marketTarget.classList.add("border-indigo-600", "text-indigo-600");
        break;

      case /^\/(merchandise|market|vending|purchasing|order|cart)/.test(path):
        this.marketTarget.classList.add("border-indigo-600", "text-indigo-600");
        break;

      case /^\/wfaq/.test(path):
        this.faqTarget.classList.add("border-indigo-600", "text-indigo-600");
        break;

      default:
        break;
    }
  }

  // 按esc就是全關
  closeHandler(event) {
    if (event.keyCode == 27) {
      leave(this.mobileMenuTarget);
      leave(this.serviceFlyoutTarget);
      leave(this.profileFlyoutTarget);
      leave(this.cartFlyoutTarget);
      leave(this.shipmentDropdownTarget);
    }
  }

  toggle() {
    toggle(this.toggleableTarget);
  }

  toggleMobile() {
    toggle(this.mobileMenuTarget);
    mobileMenuOpen = !mobileMenuOpen;
  }

  toggleProfileFlyout() {
    // 關閉其他的
    if (serviceFlyoutOpen) {
      serviceFlyoutOpen = false;
      leave(this.serviceFlyoutTarget);
    }

    if (cartFlyoutOpen) {
      cartFlyoutOpen = false;
      leave(this.cartFlyoutTarget);
    }

    if (profileFlyoutOpen) {
      leave(this.profileFlyoutTarget);
      profileFlyoutOpen = false;
    } else {
      enter(this.profileFlyoutTarget);
      profileFlyoutOpen = true;
    }
  }

  toggleCartFlyout() {
    // 關閉其他的
    if (profileFlyoutOpen) {
      profileFlyoutOpen = false;
      leave(this.profileFlyoutTarget);
    }

    if (serviceFlyoutOpen) {
      serviceFlyoutOpen = false;
      leave(this.serviceFlyoutTarget);
    }

    if (cartFlyoutOpen) {
      leave(this.cartFlyoutTarget);
      cartFlyoutOpen = false;
    } else {
      enter(this.cartFlyoutTarget);
      cartFlyoutOpen = true;
    }
  }

  toggleShipmentDropdown() {
    toggle(this.shipmentDropdownTarget);
    shipmentDropdownOpen = !shipmentDropdownOpen;
  }
}
