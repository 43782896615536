import { Controller } from "@hotwired/stimulus";

let cities,
  areas = "";

export default class extends Controller {
  static targets = [
    "forwardStore",
    "storeInfo",
    "forwardAddress",
    "cityarea",
    "city",
    "area",
    "namephone",
    "name",
    "phone",
    "collecting",
    "object",
    "heading",
  ];

  static values = {
    type: String,
  };

  initialize() {
    cities = this.cityTarget.innerHTML;
    areas = this.areaTarget.innerHTML;
  }

  connect() {
    if (this.typeValue != "shopclosed") {
      // 如果不是需要進一步輸入的操作就把SUBMIT打開
      document.getElementById("confirm").disabled = false;
    }
  }

  onChange(ev) {
    // console.log(ev.target.value);

    if (ev.target.value == "14150" || ev.target.value == "14151") {
      this.cityareaTarget.classList.remove("hidden");
      this.namephoneTarget.classList.remove("hidden");
      this.nameTarget.required = true;
      this.phoneTarget.required = true;
      document.getElementById("confirm").disabled = true;

      // document.getElementById("confirm").setAttribute("disabled", "");
    } else {
      this.cityareaTarget.classList.add("hidden");
      this.namephoneTarget.classList.add("hidden");
      this.forwardStoreTarget.classList.add("hidden");
      this.forwardAddressTarget.classList.add("hidden");
      this.nameTarget.required = false;
      this.phoneTarget.required = false;
      document.getElementById("confirm").disabled = false;
    }

    if (ev.target.value == "14150") {
      this.forwardStoreTarget.disabled = false;
      this.forwardStoreTarget.required = true;
      this.forwardAddressTarget.disabled = true;
      this.forwardAddressTarget.required = false;
      this.cityTarget.required = false;
      this.areaTarget.required = false;
      this.forwardStoreTarget.classList.remove("hidden");
      this.forwardAddressTarget.classList.add("hidden");
      this.cityareaTarget.classList.remove("hidden");
      this.cityareaTarget.classList.add("hidden");
      this.namephoneTarget.classList.remove("hidden");
      this.headingTarget.value = "toward_store";
    } else if (ev.target.value == "14151") {
      this.forwardStoreTarget.disabled = true;
      this.forwardStoreTarget.required = false;
      this.forwardAddressTarget.disabled = false;
      this.forwardAddressTarget.required = true;
      this.cityTarget.required = true;
      this.areaTarget.required = true;
      this.forwardAddressTarget.classList.remove("hidden");
      this.forwardStoreTarget.classList.add("hidden");
      this.cityareaTarget.classList.remove("hidden");
      this.namephoneTarget.classList.remove("hidden");
      this.headingTarget.value = "toward_house";
    }
  }

  onAddrInput() {
    document.getElementById("confirm").disabled = false;
  }

  onChangeCity() {
    // ensure user select city first
    this.areaTarget.disabled = false;
    var city =
      this.targets.find("city").options[this.targets.find("city").selectedIndex]
        .text;

    var options = $(areas).filter(`optgroup[label=${city}]`).html();

    if (options === undefined) {
      $("#area").html("<option selected disabled>請先選擇縣市</option>");
    } else if (options.trim()) {
      $("#area").html(
        "<option selected disabled>" + city + "服務區域</option>" + options
      );
    } else {
      $("#area").html(
        "<option selected disabled>" + city + "目前未服務</option>"
      );
    }
  }

  onInputStore(ev) {
    if (ev.target.value.match(/^\d{6}$/)) {
      this.queryStore(ev.target.value);
    }
  }

  queryStore(store) {
    var query = `store_id=${store}`;
    Rails.ajax({
      url: "/info.json",
      type: "get",
      data: query,
      success: (data) => {
        this.storeInfoTarget.innerHTML = data.store_info;
        if (data.can_pick) {
          this.storeInfoTarget.classList.remove("text-red-500");
          document.getElementById("confirm").disabled = false;
        } else {
          this.storeInfoTarget.classList.add("text-red-500");
        }
      },
      error: (data) => {
        console.error(data);
      },
    });
  }
}
