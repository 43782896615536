import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity", "plus", "minus"];

  static values = {
    marketId: String,
    id: String,
    nfo: String,
  };

  onClickPlus() {
    var quantity = this.quantityTarget.value
      ? parseInt(this.quantityTarget.value, 10)
      : 0;
    quantity += 1;
    if (quantity > 100) {
      quantity = 100;
    }

    if (this.quantityTarget.disabled == true) {
      quantity = 0;
    }
    this.quantityTarget.value = quantity;
    if (location.pathname == "/cart") {
      this.updateCart();
    }
  }

  onClickMinus() {
    var quantity = this.quantityTarget.value
      ? parseInt(this.quantityTarget.value, 10)
      : 0;
    quantity -= 1;
    if (quantity < 1) {
      quantity = 1;
    }

    if (this.quantityTarget.disabled == true) {
      quantity = 0;
    }

    this.quantityTarget.value = quantity;
    if (location.pathname == "/cart") {
      this.updateCart();
    }
  }

  onChangeCart() {
    if (location.pathname == "/cart") {
      this.updateCart();
    }
  }

  updateCart() {
    let payload = new URLSearchParams();

    payload.append(`id`, this.idValue);
    payload.append(`nfo`, this.nfoValue);
    payload.append(`market_id`, this.marketIdValue);
    payload.append(`qty`, this.quantityTarget.value);

    setTimeout(() => {
      Rails.ajax({
        url: "/cart/update",
        type: "post",
        data: payload,
        success: (data) => {
          console.log(data);
          window.location.reload();
        },
        error: (data) => {
          console.error(data);
        },
      });
    }, 500);
  }
}
