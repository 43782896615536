import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    fetch(this.urlValue).then(/* … */);
  }

  initialize() {}

  selectColorSize(event) {
    document.querySelectorAll(".label-size-color").forEach((element) => {
      if (element.id != `image-${event.params.index}`) {
        element.classList.remove(
          "border-indigo-500",
          "ring-2",
          "ring-indigo-500"
        );
        element.classList.add("border-transparent");
      }
    });
    document
      .getElementById(`label-size-color-${event.params.nfo}`)
      .classList.add("border-indigo-500", "ring-2", "ring-indigo-500");

    document.querySelectorAll(".heroicon").forEach((element) => {
      if (element.id != `image-${event.params.index}`) {
        element.classList.remove("invisible");
        element.classList.add("invisible");
      }
    });
    document
      .getElementById(`heroicon-${event.params.nfo}`)
      .classList.remove("invisible");
  }
}
